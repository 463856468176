


import moment from "moment";

export default {
    name: "OfficeInteractionsModal",
    props: {
        id: {
            type: String,
            default: "officeInteractionsModal"
        },

    },
    data() {
        return {
            officeContacts: [],
            isTableLoading: false,
            showTable: false,
            selectedMonth: moment().month() + 1,
            selectedYear: moment().year(),
        }
    },
    computed: {
        tableConfig() {
            let month = this.selectedMonth;
            if (String(this.selectedMonth).length === 1) {
                month = "0" + String(this.selectedMonth);
            }

            return {
                columns: [
                    {
                        headline: "Start",
                        sort: true
                    },
                    {
                        headline: "End",
                    },
                    {
                        headline: "Subject",
                    },
                    {
                        headline: "Participants",
                        breakpoint: "all",
                        align: "start"
                    },
                    {
                        headline: "Actions"
                    }
                ],
                pagination: 10,
                ajaxUrl: "/api/graph/interactions/list/" + this.selectedYear + "-" + month,
            }
        },
        monthOptions() {
            const monthOptions = [];
            const months = moment.months();
            for (let i = 1; i <= months.length; i++) {
                monthOptions.push({
                    value: i,
                    label: months[i - 1]
                })
            }
            return monthOptions;
        },
        yearOptions() {
            const year = moment().year()
            const length = 11;
            const start = year - 5;
            return Array.from({length}, (_, i) => start + i)
        }
    },
    watch: {},
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.showTable = true;
        })
    },
    methods: {
        importOfficeInteraction(graphId) {
            this.$axios.post('/api/graph/interaction/import/' + graphId)
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        htmlMessage: this.escapeHtml(response.data.message),
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                });
        }
    }
}
