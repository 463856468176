

// use Object.assign or maybe {...spread} for setting this into vars
const DEFAULT_COORDINATES = {lat: 40.714, lng: -74.005};

export default {
    name: "Gmap",
    props: {
        area: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            MAX_RADIUS_IN_MILES: 2500,
            METERS_IN_MILE: 1609.34,

            mapCenter: Object.assign({}, DEFAULT_COORDINATES),
            draggable: true,
            options: {
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
            },
            mapOptions: {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false
            },
            position: Object.assign({}, DEFAULT_COORDINATES),
            positionSelected: false,
            eventTimeout: null,
            radiusInMiles: 0,
            radiusInMeters: 0,
            radiusInMetersString: '0',
            mapKey: this.generateUUID(),
            timeout: null,
            place: null,
        }
    },
    computed: {
        areaProperty() {
            if (this.positionSelected) {
                return {
                    lat: this.position ? this.position.lat : null,
                    lng: this.position ? this.position.lng : null,
                    radius: this.radiusInMiles,
                    place: this.place
                }
            } else {
                return null;
            }
        },
    },
    watch: {
        areaProperty: {
            handler() {
                clearTimeout(this.eventTimeout);
                this.eventTimeout = setTimeout(() => {
                    // this.$emit('area-updated', this.area);
                    this.$emit('update:area', this.areaProperty);
                }, 2000);
            },
            deep: true
        },
        area: {
            handler(area) {
                if (area) {
                    this.position.lat = area.lat;
                    this.position.lng = area.lng;
                    this.radiusInMiles = area.radius;
                    this.place = area.place;
                    this.updateRadiusInMeters();
                    this.positionSelected = true
                }
            },
            immediate: true,
            deep: true,
        },
        radiusInMetersString() {
            this.radiusInMeters = Number(this.radiusInMetersString);
            this.radiusInMiles = Math.round((this.radiusInMeters / this.METERS_IN_MILE) * 2) / 2;
        },
        position: {
            handler() {
                this.mapCenter = this.position;
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {

    },
    methods: {
        mark(event) {
            this.position.lat = event.latLng.lat();
            this.position.lng = event.latLng.lng();
            this.place = null;
            this.positionSelected = true;
            if (!this.radiusInMiles) this.radiusInMiles = 50;
        },
        // dragFinished(event) {
        //     console.log("Drag finished event", event);
        // },
        hideAllMarkers() {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].visibility = false;
            }
        },
        setPlace(place) {
            if (place && place.geometry && place.geometry.location && place.geometry.location.lat() && place.geometry.location.lng()) {
                this.position.lat = place.geometry.location.lat();
                this.position.lng = place.geometry.location.lng();
                this.place = place.formatted_address;
                this.positionSelected = true;
                if (!this.radiusInMiles) this.radiusInMiles = 50;
            } else if (place && place.name === "") {
                this.positionSelected = false;
            } else {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "danger",
                    title: "Invalid place",
                    message: "Please use another one",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            }
        },
        resetMap() {
            this.radiusInMiles = 0;
            this.radiusInMeters = 0;
            this.radiusInMetersString = '0';
            this.mapCenter = Object.assign({}, DEFAULT_COORDINATES);
            this.position = Object.assign({}, DEFAULT_COORDINATES);
            this.positionSelected = false;
            this.mapKey = this.generateUUID();
        },
        updateRadiusInMeters() {
            this.radiusInMeters = (this.radiusInMiles * this.METERS_IN_MILE * 2) / 2;
            this.radiusInMetersString = String(this.radiusInMeters);
        },
        updateWithDelay() {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.updateRadiusInMeters();
            }, 1750);
        }
    }
}
