
export default {
    name: "RangeWithInput",
    props: {
        label: {
            type: String,
            required: true,
        },
        min: {
            type: [Number, String],
            required: true
        },
        max: {
            type: [Number, String],
            required: true
        },
        value: {
            type: [Number, String],
            default: null,
        }
    },
    data() {
        return {
            debounceTimeout: null,
            inputId: 'rangeSlider' + this.generateUUID(),
        };
    },
    computed: {
        rangeVal: {
            get() {
                return this.value;
            },
            set(val) {
                clearTimeout(this.debounceTimeout)
                this.debounceTimeout = setTimeout(() => {
                    this.$emit('input', val)
                }, 500)
            }
        }
    }
}
