
export default {
    name: "CurrencyChange",
    props: {
        number: {
            type: [Number, String],
            required: true
        },
        currency: {
            type: String,
            required: true
        },
    },
}
