
import modals from "../../mixins/modals";
import interactions from "../../mixins/interactions";
import ConfirmModal from "../confirm-modal";
import EditNoteModal from "../../components/notes/edit-note-modal";
import ShowNoteModal from "../../components/notes/show-note-modal";
import InteractionsTable from "./interactions-table";
import EditInteractionModal from "./edit-interaction-modal";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "InteractionsCard",
    components: {
        ConfirmModal,
        EditInteractionModal,
        InteractionsTable,
        ShowNoteModal,
        EditNoteModal,
    },
    mixins: [modals, interactions, optionButtonTooltip],
    props: {
        initialCompany: {
            type: Object,
            required: false,
            default: null,
        },
        initialContact: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        hasCompany() {
            return (this.initialCompany && this.initialCompany.id > 0);
        },
        hasContact() {
            return (this.initialContact && this.initialContact.id > 0);
        },
        initialContactsUrl() {
            if (this.hasCompany) {
                return `/api/contacts/for_select?company=${this.initialCompany.id}`
            } else if (this.hasContact) {
                return `/api/contacts/${this.initialContact.id}`
            } else {
                return null;
            }
        },
        noteKey() {
            return JSON.stringify(this.noteToEdit);
        },
    },
    methods: {
        ucfirst(string) {
            return (string[0].toUpperCase() + string.slice(1));
        },
    }
}
