
export default {
    name: "InteractionDownloadModal",
    data() {
        return {
            from: null,
            to: null
        }
    },
    computed: {
        exportUrl() {
            const query = {
                userId: this.userInfo.id,
                from: this.from,
                to: this.to,
                hideEmails: true
            }
            return this.$axios.defaults.baseURL + "/api/interactions/excel_export?" + this.buildQueryString(query);
        }
    },
    methods: {
        downloadInteractions() {
            window.open(this.exportUrl, '_blank').focus();
            this.$emit('modal-close')
        },
    }
}
