
import moment from "moment";
import modals from "../mixins/modals";
import EditFavoritePageModal from "./edit-favorite-page-modal";
import TableActions from "./table-actions";
import ConfirmModal from "./confirm-modal";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "FavoritePagesCard",
    components: {ConfirmModal, TableActions, EditFavoritePageModal},
    mixins: [optionButtonTooltip, modals],
    data() {
        return {
            pages: [],
            loading: false,
            updated: moment().unix(),
            pageToEdit: null,
            pageToDelete: null,
            config: {
                columns: [
                    {
                        headline: "Page",
                    },
                    {
                        headline: "URL",
                        breakpoint: "all"
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end"
                    }
                ]
            }
        }
    },
    computed: {
        rows() {
            return Object.keys(this.pages).map(key => {
                return [
                    {
                        type: "title",
                        url: this.pages[key].url + (this.pages[key].hash ? this.pages[key].hash : ''),
                        title: this.pages[key].title,
                    },
                    {
                        text: this.pages[key].url + (this.pages[key].hash ? this.pages[key].hash : ''),
                    },
                    {
                        type: "actions",
                        md5: key,
                        title: this.pages[key].title,
                        url: this.pages[key].url,
                        hash: this.pages[key].hash,
                    }
                ]

            })
        },
    },
    watch: {
        updated() {
            setTimeout(() => this.initializeOptionButtonTooltips('updated'), 250);
        },
    },
    created() {
        this.loadPages(true);
    },
    methods: {
        loadPages(init = false) {
            this.loading = true;
            this.$axios.get("/api/get_favorite_pages").then(response => {
                this.pages = response.data;
                this.updated = moment().unix();

                if (init && (Array.isArray(this.pages) && !this.pages.length || !Object.keys(this.pages).length)) {
                    this.$emit("empty")
                }

            }).finally(() => {
                this.loading = false;
            })
        },
        deletePage(hash) {
            this.pageToDelete = hash;
            this.modal = this.openModal(this.$refs.deletePageModal);
        },
        doDeletePage(hash) {
            this.loading = true;
            this.$axios.post("/api/delete_favorite_page", {
                hash
            }).then((response) => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Saved",
                    message: response.data.message,
                    id: toastId,
                })
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
                this.loadPages();
            }).finally(() => {
                this.loading = false;
            })
        },
        cancelDeletePage() {
            this.pageToDelete = null;
            this.closeModal(this.modal);
        },
        editPage(item) {
            this.pageToEdit = item;
            this.modal = this.openModal(this.$refs.editPageModal);
        },
        onPagesUpdated() {
            this.pageToEdit = null;
            this.closeModal(this.modal);
            this.loadPages();
        },
    }
}
