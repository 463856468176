
import InvestorTargetsTable from "./investor-targets-table.vue";

export default {
    name: "InvestorTargetsModal",
    components: {InvestorTargetsTable},
    props: {
        id: {
            type: String,
            required: false,
            default: "investorTargetsModal"
        },
        company: {
            type: Object,
            required: true,
        }
    },
    mounted(){
        this.$refs.modal.addEventListener('hidden.bs.modal',  ()  =>{
            this.$emit('closed', this.item)
        })
    },
}
