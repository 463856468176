
import {mapActions, mapGetters} from "vuex";
import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";
import InfoText from "../info-text";
import fileDownload from "../../mixins/file-download";
import contactBulkOperationForm from "../contacts/contact-bulk-operation-form";

export default {
    name: "InteractionBulkOperationModal",
    components: {InfoText, LoadingSpinner, SaveButton, contactBulkOperationForm},
    mixins: [fileDownload],
    props: {
        filters: {
            type: Object,
            default: () => {
                return {}
            }
        }

    },
    data() {
        return {
            contactLoading: true,
            operation: "add-summary",
            relevanceOptions: [
                {value: 'relevant', label: 'Relevant'},
                {value: 'irrelevant', label: 'Irrelevant'},
                {value: 'unclear', label: 'Unclear'}
            ],
            visibilityOptions: [
                {value: false, label: 'all'},
                {value: true, label: 'me'}
            ],
            running: false,
            bulkInteractions: null,
            loading: false,
            relevanceFinal: true,
            isPrivate: false,
            // bulkInteractionFiltersHash: null,
            isInteractionOption: true,
            bulkContacts: null,
            contactFilters: {}
        }
    },
    computed: {
        ...mapGetters({
            bulkInteractionFiltersHash: 'app/bulkInteractionFiltersHash',
            bulkContactFiltersHash: 'app/bulkContactFiltersHash',
        }),
        selectedRelevance: {
            get() {
                switch (this.relevanceFinal) {
                    case true:
                        return 'relevant';
                    case false:
                        return 'irrelevant';
                    default:
                        return 'unclear';
                }
            },
            set(newValue) {
                switch (newValue) {
                    case 'relevant':
                        this.relevanceFinal = true;
                        break;
                    case 'irrelevant':
                        this.relevanceFinal = false;
                        break;
                    default:
                        this.relevanceFinal = null;
                }
            }
        },
        bulkOptions() {
            return [
                {value: 'add-summary', label: 'Add Summary'},
                {value: 'visibility', label: 'Set Visibility'},
                {value: 'relevance', label: 'Set Relevance'},
                {value: 'sync-down', label: 'Sync to Outlook', disabled: !!this.event || !this.userInfo.m365_connected},
                {
                    value: 'create-CallLog',
                    label: 'Create Call Log',
                    disabled: !this.userInfo.roles.includes("ROLE_RESEARCH_ANALYST")
                },
            ]
        },
    },
    watch: {
        operation() {
            // Add stuff if needed
        }
    },
    created() {
        this.loadBulkInteractions();
    },
    methods: {
        ...mapActions({
            setBulkInteractionFiltersHash: "app/setBulkInteractionFiltersHash",
            setBulkContactFiltersHash: "app/setBulkContactFiltersHash",
        }),

        async submitForm() {
            // this.setBulkInteractionFiltersHash(this.bulkInteractionFiltersHash).then(async () => {
            switch (this.operation) {
                case "add-summary":
                    this.$emit("submitted");
                    this.$router.push(`/interactions/bulk/${this.bulkInteractionFiltersHash}`)
                    break;
                case "relevance":
                    this.running = true;

                    // eslint-disable-next-line no-case-declarations
                    for (let i = 0; i < this.bulkInteractions.length; i++) {
                        const interaction = this.bulkInteractions[i];
                        await this.$axios.patch("/api/interactions/" + interaction.id, {
                            isRelevant: this.relevanceFinal,
                        }, {
                            headers: {
                                'Content-Type': 'application/merge-patch+json'
                            }
                        })
                    }

                    this.running = false
                    this.$emit("submitted",);

                    break;
                case "visibility":
                    this.running = true;
                    // eslint-disable-next-line no-case-declarations
                    for (let i = 0; i < this.bulkInteractions.length; i++) {
                        const interaction = this.bulkInteractions[i];
                        if ((interaction.userId === this.userInfo.id) && (!interaction.event)) {
                            await this.$axios.patch("/api/interactions/" + interaction.id, {
                                private: this.isPrivate,
                            }, {
                                headers: {
                                    'Content-Type': 'application/merge-patch+json'
                                }
                            })
                        }
                    }

                    this.running = false
                    this.$emit("submitted");

                    break;
                case "sync-down":
                    this.running = true;
                    // eslint-disable-next-line no-case-declarations
                    const interactionIds = this.bulkInteractions.map(i => i.id);
                    this.$axios.post("/api/interactions/sync_to_outlook", {
                        interactionIds
                    })
                        .then(async (response) => {
                            const failedInteractionCount = response.data.failed;
                            const succeededInteractionCount = response.data.succeeded;
                            let type = "warning";
                            if (failedInteractionCount > 0 && succeededInteractionCount === 0) {
                                type = "danger";
                            } else if (failedInteractionCount === 0 && succeededInteractionCount > 0) {
                                type = "success";
                            }

                            let message = `${succeededInteractionCount} Interactions have been synced to Outlook successfully.`;
                            if (failedInteractionCount > 0) {
                                message += ` ${failedInteractionCount} Interactions failed.`;
                            }

                            const toastId = this.generateUUID();
                            this.addToast({
                                type,
                                title: "Interaction Synchronization",
                                message,
                                id: toastId,
                            });
                            await this.$nextTick();
                            this.toggleToast(toastId);
                        })
                        .finally(() => {
                            this.running = false;
                            this.$emit("submitted");
                        });
                    break;
                case "download":
                    this.downloadContacts();
                    break;
                case "create-CallLog":
                    this.running = true;
                    this.$axios.post("/api/interactions/create_call_log", {
                        interactionIds: this.bulkInteractions.map(i => i.id)
                    }).then(() => {
                        this.$emit("submitted");

                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Cal Logs created",
                            message: "Cal Logs have been created successfully!",
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        })

                    }).finally(() => {
                        this.running = false;
                    })
                    break;
                default:
                    console.error(`Operation ${this.operation} is not allowed.`);
            }
            // });

        },
        async loadBulkInteractions() {
            this.loading = true;
            this.contactLoading = true;

            const responseFilters = await this.$axios.post("/api/interactions/save_bulk_filters", {filters: this.filters});
            // this.bulkInteractionFiltersHash = responseFilters.data.hash;
            // this.bulkContactFiltersHash = responseFilters.data.hash_contacts;

            await this.setBulkInteractionFiltersHash(responseFilters.data.hash);
            await this.setBulkContactFiltersHash(responseFilters.data.hash_contacts)

            // Reload Interactions
            const interactionsPromise = this.$axios.get("/api/interactions/bulk_interactions", {
                params: {
                    hash: this.bulkInteractionFiltersHash
                }
            }).then(response => {
                this.bulkInteractions = response.data["hydra:member"];

                // Prepare filter settings for Contact download
                this.contactFilters = {
                    ids: this.bulkInteractions.map(interaction =>
                        interaction.interactionParticipants.map(participant => participant.contact.id)).flat()
                }
            }).finally(() => {
                this.loading = false;
            });


            const contactsPromise = this.$axios.get("/api/contacts/bulk_contacts", {
                params: {
                    hash: this.bulkContactFiltersHash
                }
            }).then(response => {
                this.bulkContacts = {
                    hash: this.bulkContactFiltersHash,
                    count: response.data["hydra:totalItems"],
                    contactCount: response.data["hydra:totalItems"],
                };
            })

            // Only when contact and interaction promise are both done, contact bulk operation can be done.
            Promise.all([contactsPromise, interactionsPromise]).finally(() => {
                this.contactLoading = false;
            });

        },
        submitted() {
            this.$emit("submitted");
        },
        exportAsExcel() {
            this.$emit('export-as-excel', this.contactFilters);
        },
    }
}
