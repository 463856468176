
import moment from "moment/moment";

export default {
    name: "InteractionDateCell",
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            userTimezone: moment.tz.guess(),
        }
    },
    computed: {
        startEndOnSameDay() {
            // eslint-disable-next-line max-len
            return this.formatDateTime(this.cell.start, this.userTimezone, 'MM/DD/YYYY') === this.formatDateTime(this.cell.end, this.userTimezone, 'MM/DD/YYYY');
        },
        title() {
            if (this.cell.isBlocker) {
                return this.formatDateTime(this.cell.start, this.cell.timezone, 'MM/DD/YYYY hh:mm A zz') + " - " +
                    this.formatDateTime(this.cell.end, this.cell.timezone, this.startEndOnSameDay ? 'hh:mm A zz' : 'MM/DD/YYYY hh:mm A zz');
            } else {
                return this.formatDateTime(this.cell.start, this.cell.timezone, 'MM/DD/YYYY hh:mm A zz');
            }
        }
    },
}
