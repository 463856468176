
import {mapGetters} from "vuex";
import moment from "moment";
import modals from "../../mixins/modals";
import interactions from "../../mixins/interactions";
import ConfirmModal from "../confirm-modal";
import ShowNoteModal from "../../components/notes/show-note-modal";
import EditNoteModal from "../../components/notes/edit-note-modal";
import InteractionsTable from "./interactions-table";
import EditInteractionModal from "./edit-interaction-modal";

export default {
    name: "UpcomingInteractions",
    components: {
        ConfirmModal,
        EditInteractionModal,
        InteractionsTable,
        ShowNoteModal,
        EditNoteModal,
    },
    mixins: [modals, interactions],
    props: {
        futureDaysLimit: {
            type: Number,
            required: false,
            default: 7
        }
    },
    computed: {
        ...mapGetters({
            userData: "app/userData",
        }),
        noteKey() {
            return JSON.stringify(this.noteToEdit);
        },
    },
    methods: {
        interactionsUpdated(data) {
            this.tableUpdated = (this.tableUpdated ? this.tableUpdated : moment().unix()) + 1; // Increment timestamp to trigger reload (must be timestamp!)
            this.closeModal(this.modal);

            let isCurrentUserParticipant = false;
            for (const participant of data.interactionParticipants) {
                const participantId = parseInt(participant.contact["@id"].split("/")[3]);
                if (this.userData.contact_id === participantId) {
                    isCurrentUserParticipant = true;
                    break;
                }
            }

            const toastReasons = [];

            if (!isCurrentUserParticipant) {
                toastReasons.push("you are not participating")
            }

            const start = moment.tz(data.start, data.timezone);
            // const end = moment.tz(data.end, data.timezone);
            const limit = moment().add(this.futureDaysLimit, 'days');

            if (start >= limit) {
                toastReasons.push("it is more than " + this.futureDaysLimit + " days in the future");
            } else if (start < moment()) {
                toastReasons.push("it is in the past");
            }

            if (toastReasons.length) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Interaction created/updated",
                    message: `The interaction was successfully created/updated. It
                    is not listed in your upcoming interactions, because ${toastReasons.join(", and ")}.`,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            }
        }
    }
}
