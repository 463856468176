
import ContactName from "../contacts/contact-name";
import LoadingSpinner from "../loading-spinner";

export default {
    name: "ShowInteractionModal",
    components: {LoadingSpinner, ContactName},
    props: {
        id: {
            type: String,
            default: () => "editInteractionsModal"
        },
        interactionId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            interaction: null,
        }
    },
    computed: {
        formattedDuration() {
            if (this.interaction.durationMinutes <= 0) {
                return ""
            } else if (this.interaction.durationMinutes <= 60) {
                return this.interaction.durationMinutes + "min";
            } else {
                return Math.floor(this.interaction.durationMinutes / 60) + "h " + (this.interaction.durationMinutes % 60) + "min";
            }
        },
        relevanceString() {
            switch (this.interaction.isRelevant) {
                case true:
                    return 'Relevant';
                case false:
                    return 'Irrelevant';
                default:
                    return 'Unclear';
            }
        }
    },
    watch: {
        interactionId: {
            handler(val) {
                this.interaction = null;
                if (val) {
                    this.$axios.get(`/api/interactions/${val}?groups[]=interaction:show&groups[]=interaction:read`)
                        .then((response) => {
                            this.interaction = response.data;
                        }).finally(() => {
                            this.showLoading = null;
                        });
                }
            },
            immediate: true
        }
    }
}
