
export default {
    name: "InteractionNotesCell",
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
    methods: {
        showNoteOfInteraction(noteId, interactionId) { // Here we have two parameters!
            console.log("Showing note...", {noteId, interactionId})
            this.$emit("edit-note-of-interaction", {noteId, interactionId});
        },
    },
}
