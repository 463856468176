
export default {
    name: "ExpandableText",
    props: {
        text: {
            type: String,
            default: "",
        },
        maxLength: {
            type: Number,
            default: 200,
        },
        displayInline: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            showingMore: false,
            collapseId: "",
        }
    },
    computed: {
        isTextTooLong() {
            return this.text.length > this.maxLength;
        },
        truncated() {
            if (this.text.length <= this.maxLength) {
                return this.text;
            }
            return this.text.substring(0, this.maxLength) + '\u2026'
        }
    },
    watch: {
    },
    mounted() {
        // this will be used as selector and selectors must start with a letter
        this.collapseId = 'c' + this.createSimpleHash(this.text + this.generateUUID());
    },
    methods: {
        toggleShowMore() {
            this.showingMore = !this.showingMore;
        }
    }
}
