
export default {
    name: "GmapsAddress",
    props: {
        address: {
            type: String,
            required: true,
        }
    },
    computed: {
        mapsUrl() {
            if (this.address && this.address !== "") {
                return "https://www.google.com/maps/search/?api=1&query=" + encodeURI(this.address);
            } else {
                return null;
            }
        }
    }
}
