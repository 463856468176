
export default {
    name: "ParticipantsAndCompanies",
    props: {
        participants: {
            type: Array,
            required: true,
        },
        companies: {
            type: Array,
            default: () => [],
        },
        target: {
            type: String,
            default: "",
        },
        listParticipants: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            uuid: this.generateUUID()
        }
    },
    computed: {

        allCompanies() {
            const all = {};
            for (const company of this.companies) {
                all[company.id] = company;
            }
            for (const participant of this.participants) {
                const company = participant.contact.company;
                if (company) {
                    all[company.id] = company;
                }
            }
            return all;
        },
    },

    methods: {},
}
