
export default {
    name: "InteractionDetailsCell",
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
}
