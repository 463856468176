
import moment from "moment";
import SaveButton from "../save-button";
import LoadingSpinner from "../loading-spinner";
import iriPreparation from "../../mixins/iri-preparation";
import interactionConflicts from "../../mixins/interaction-conflicts";
import ParticipantsByCompany from "./participants-by-company";
import ConflictsWarning from "./conflicts-warning.vue";

export default {
    name: "EditInteractionModal",
    components: {ConflictsWarning, ParticipantsByCompany, LoadingSpinner, SaveButton},
    mixins: [iriPreparation, interactionConflicts],
    props: {
        id: {
            type: String,
            default: () => "editInteractionsModal"
        },
        interaction: {
            type: Object,
            default: null
        },
        initialContactsUrl: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            interactionWorkingCopy: {},
            loading: false,
            selectedContacts: [],
            participantsNotEditable: [],
            selectedCompanies: [],
            companiesNotEditable: [],
            interactionParticipantTemplate: {},
            interactionHasCompanyTemplate: {},
            interactionTypes: [],
            interactionRelevances: [],
            initialContacts: null,
            initialCompanies: null,
            loadingContacts: false,
            durationOptions: [
                {value: 15, label: "15 minutes"},
                {value: 30, label: "30 minutes"},
                {value: 45, label: "45 minutes"},
                {value: 60, label: "1 hour"},
                {value: 120, label: "2 hours"},
                {value: 0, label: "immediate"},
                {value: "other", label: "other"},
            ],
            order: {
                details: 'one',
                summary: 'two',
                conflicts: 'three',
            },
            timezone: 'America/New_York',
            start: null,
            end: null,
            duration: null,
            showEnd: false,
        }
    },
    computed: {
        participantsRequired: {
            get() {
                return this.interactionWorkingCopy.interactionType?.VID !== 'BLOCKER' &&
                    this.interactionWorkingCopy.interactionType?.VID !== 'ICAL_EXPORT' &&
                    this.interactionWorkingCopy.interactionType?.VID !== 'PANEL' &&
                    this.interactionWorkingCopy.interactionType?.VID !== 'PRESENTATION';
            },
            immediate: true,
            deep: true,
        },
        event() {
            return this.interaction.event
        },
        checkBackDate: {
            get() {
                if (this.interactionWorkingCopy && this.interactionWorkingCopy.followUpDate) {
                    return moment(this.interactionWorkingCopy.followUpDate).format("YYYY-MM-DD");
                } else {
                    return null;
                }
            },
            set(newValue) {
                this.$set(this.interactionWorkingCopy, "followUpDate", newValue || null)
            }
        },
        isNew() {
            return !(this.interactionWorkingCopy && this.interactionWorkingCopy.id > 0);
        },
        interactionParticipants() {
            return this.selectedContacts.map((contact) => {
                const newInteractionParticipant = structuredClone(this.interactionParticipantTemplate);
                newInteractionParticipant.contact = contact['@id'];
                return newInteractionParticipant;
            });
        },
        interactionCompanies() {
            return this.selectedCompanies.map((company) => {
                const newInteractionCompany = structuredClone(this.interactionHasCompanyTemplate);
                newInteractionCompany.company = company['@id'];
                this.$delete(newInteractionCompany, "id")
                return newInteractionCompany;
            });
        },
        isEditable() {
            return this.interactionWorkingCopy.isEditableEverything;
        },
        isNotBlocker: {
            get() {
                return !(this.interactionWorkingCopy.interactionType?.VID === 'BLOCKER');
            },
            immediate: true,
            deep: true,
        },
        isNotICalExport: {
            get() {
                return !(this.interactionWorkingCopy.interactionType?.VID === 'ICAL_EXPORT');
            },
            immediate: true,
            deep: true,
        },
        relevance: {
            get() {
                switch (this.interactionWorkingCopy.isRelevant) {
                    case true:
                        return 'relevant';
                    case false:
                        return 'irrelevant';
                    default:
                        return 'unclear';
                }
            },
            set(newValue) {
                switch (newValue) {
                    case 'relevant':
                        this.interactionWorkingCopy.isRelevant = true;
                        break;
                    case 'irrelevant':
                        this.interactionWorkingCopy.isRelevant = false;
                        break;
                    default:
                        this.interactionWorkingCopy.isRelevant = null;
                }
            }
        },
        initialTimezones() {
            return this.userInfo.most_used_timezones.map(tz => {
                return {
                    label: tz,
                    value: tz,
                }
            });
        },
        filteredInteractionTypes() {
            if (!this.event) {
                return this.interactionTypes.filter(it => it.VID !== "BLOCKER");
            }

            return this.interactionTypes;
        },
        isSummaryEditable() {
            if(!this.interactionWorkingCopy) {
                return false;
            }
            return this.userInfo.id === this.interactionWorkingCopy.checkBackInUserId || this.interactionWorkingCopy.checkBackInUserId === null;
        },
    },
    watch: {
        // interaction: {
        //     handler(){
        //         this.preventCalculation = true;
        //         this.resetWorkingCopy();
        //         this.$nextTick(()=>{
        //             this.preventCalculation = false;
        //         })
        //     },
        //     deep: true,
        // },
        interactionWorkingCopy() {
            if (!this.interactionWorkingCopy.timezone || this.isNew) {
                this.timezone = moment.tz.guess();
            } else {
                this.timezone = this.interactionWorkingCopy.timezone;
            }
            this.start = moment(this.interactionWorkingCopy.start).tz(this.timezone).format('YYYY-MM-DD HH:mm');
            this.end = moment(this.interactionWorkingCopy.end).tz(this.timezone).format('YYYY-MM-DD HH:mm');
        },
        duration: {
            handler(val) {
                if (val !== "other" && this.start) {
                    const start = structuredClone(this.start);
                    this.end = moment(start).add(val, 'm').format('YYYY-MM-DD HH:mm:ss');
                    this.showEnd = false;
                } else {
                    this.showEnd = true;
                }
            },
            immediate: true,
        },
        start() {
            if (this.duration !== "other" && this.start) {
                const start = structuredClone(this.start);
                this.end = moment(start).add(this.duration, 'm').format('YYYY-MM-DD HH:mm');
            }
        },
        interaction: {
            handler() {
                if (this.interaction.started) {
                    this.order.summary = 'one';
                    this.order.details = 'two';
                }
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
        this.loadInteractionTemplates();
        this.loadInteractionTypes();

        if (this.initialContactsUrl) {
            this.$refs.modal.addEventListener('show.bs.modal', () => {
                if (!this.loaded) {
                    this.loadCompanyContacts()
                }
            })
        }

        this.$refs.modal.addEventListener('hide.bs.modal', () => {
            this.$emit("closed");
        });
    },
    created() {
        this.resetWorkingCopy();
    },
    methods: {
        loadCompanyContacts() {
            if (!this.initialContactsUrl) {
                return;
            }
            this.loadingContacts = true;
            this.$axios.get(this.initialContactsUrl).then((response) => {
                this.initialContacts = response.data['hydra:member'];
            }).finally(() => {
                this.loadingContacts = false;
            })
        },
        resetWorkingCopy() {
            this.interactionWorkingCopy = structuredClone(this.interaction);

            if (this.interactionWorkingCopy.event) {
                this.interactionWorkingCopy.private = false;
            }

            // Adds current user to interaction participants.
            if (!this.interaction.id) {
                const newInteractionParticipant = structuredClone(this.interactionParticipantTemplate);
                newInteractionParticipant.contact = {
                    "@id": '/api/contacts/' + this.userInfo.contact_id,
                    id: this.userInfo.contact_id,
                    nameWithCompany: this.userInfo.name,
                };
                newInteractionParticipant.isAddedManually = true;
                this.interactionWorkingCopy.interactionParticipants.push(newInteractionParticipant);
            }

            if (this.interactionWorkingCopy.interactionParticipants) {
                this.selectedContacts = this.interactionWorkingCopy.interactionParticipants.filter((interactionParticipant) => {
                    return interactionParticipant.isAddedManually;
                }).map((interactionParticipant) => {
                    return interactionParticipant.contact;
                });

                this.participantsNotEditable = this.interactionWorkingCopy.interactionParticipants.filter((interactionParticipant) => {
                    return !interactionParticipant.isAddedManually;
                });
            }

            if (this.interactionWorkingCopy.interactionHasCompanies) {
                this.selectedCompanies = this.interactionWorkingCopy.interactionHasCompanies.filter((interactionHasCompany) => {
                    return interactionHasCompany.isAddedManually;
                }).map((interactionHasCompany) => {
                    return interactionHasCompany.company;
                });

                this.companiesNotEditable = this.interactionWorkingCopy.interactionHasCompanies.filter((interactionHasCompany) => {
                    return !interactionHasCompany.isAddedManually;
                }).map((interactionHasCompany) => {
                    return interactionHasCompany.company;
                });
            }


            if (this.interactionWorkingCopy.start && this.interactionWorkingCopy.end) {
                this.durationOptions.forEach((duration) => {
                    if (duration.value === this.interactionWorkingCopy.durationMinutes) {
                        this.duration = duration.value;
                    }
                })

                if (this.duration === null) {
                    this.duration = "other";
                }

            } else {
                this.duration = this.defaultDuration;
            }

        },
        loadInteractionParticipantTemplate() {
            this.$axios.get('/api/interaction_participant/empty')
                .then((response) => {
                    this.interactionParticipantTemplate = response.data;
                });
        },
        loadInteractionTemplates() {
            this.$axios.get('/api/interaction/templates')
                .then((response) => {
                    this.interactionParticipantTemplate = response.data.interactionParticipant;
                    this.interactionHasCompanyTemplate = response.data.interactionCompany;
                });
        },
        submitForm() {

            // Not sure why formulate required isn't working properly
            if (this.participantsRequired && (this.selectedContacts.length + this.participantsNotEditable.length) === 0) {
                alert("Please select at least one participant");
                return false;
            }

            // Do some hemdsärmlig validation

            if (!this.start) {
                alert("Please set a start date/time for this interaction.");
                return false;
            }

            if (!this.end) {
                alert("Please set an end date/time for this interaction or specify a duration.");
                return false;
            }

            //  Do submit...

            if (this.interactionWorkingCopy.syncBidirectional &&
                !this.interactionWorkingCopy.isM365Draft &&
                !confirm("This interaction is synced to Outlook. All participants will be notified about changes. Do you want to continue?")) {
                return;
            }

            // avoid null database exception
            if (!this.interactionWorkingCopy.meetingPoint) {
                this.interactionWorkingCopy.meetingPoint = "";
            }

            if (this.interactionWorkingCopy.event) {
                this.interactionWorkingCopy.event = this.interactionWorkingCopy.event['@id'];
            }

            this.interactionWorkingCopy.start = moment.tz(this.start, this.timezone).utc().toISOString();
            this.interactionWorkingCopy.end = moment.tz(this.end, this.timezone).utc().toISOString();
            this.interactionWorkingCopy.timezone = this.timezone;

            this.loading = true;
            if (this.isNew) {
                this.interactionWorkingCopy.interactionParticipants = this.interactionParticipants;
                this.interactionWorkingCopy.interactionHasCompanies = this.interactionCompanies;

                // Prepare IRIs
                this.interactionWorkingCopy = this.prepareIri(this.interactionWorkingCopy);

                this.$axios.post("/api/interactions", this.interactionWorkingCopy)
                    .then((response) => {
                        this.$emit("interaction-updated", response.data);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {

                const existingParticipants = [];
                let allContacts = this.selectedContacts.map(x => x["@id"]);
                allContacts = [...allContacts, ...this.participantsNotEditable.map(p => p.contact["@id"])];
                // add already existing participants (if not removed)
                this.interactionWorkingCopy.interactionParticipants.forEach((p) => {
                    if (allContacts.includes(p.contact["@id"])) {
                        existingParticipants.push(p["@id"]);
                    }
                });
                const existingContacts = this.interactionWorkingCopy.interactionParticipants.map(x => x.contact).map(x => x["@id"]);
                // add new participants
                this.interactionParticipants.forEach((p) => {
                    if (!existingContacts.includes(p.contact)) {
                        existingParticipants.push(p);
                    }
                })
                this.interactionWorkingCopy.interactionParticipants = existingParticipants;

                const existingCompanies = [];
                let allCompanies = this.selectedCompanies.map(x => x['@id']);
                allCompanies = [...allCompanies, ...this.companiesNotEditable.map(p => p["@id"])];
                this.interactionWorkingCopy.interactionHasCompanies.forEach((c) => {
                    if (allCompanies.includes(c.company["@id"])) {
                        existingCompanies.push(c["@id"]);
                    }
                });
                const savedCompanies = this.interactionWorkingCopy.interactionHasCompanies.map(x => x.company).map(x => x["@id"]);
                this.interactionCompanies.forEach((c) => {
                    if (!savedCompanies.includes(c.company)) {
                        existingCompanies.push(c);
                    }
                });
                this.interactionWorkingCopy.interactionHasCompanies = existingCompanies;

                // Prepare iri
                this.interactionWorkingCopy = this.prepareIri(this.interactionWorkingCopy);

                this.$axios.patch("/api/interactions/" + this.interactionWorkingCopy.id, this.interactionWorkingCopy, {
                    headers: {
                        'Content-Type': 'application/merge-patch+json'
                    }
                })
                    .then((response) => {
                        // console.log("Emitting event", response.data);
                        this.$emit("interaction-updated", response.data);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        loadInteractionTypes() {
            this.$axios.get('/api/interaction_types').then((response) => {
                this.interactionTypes = response.data['hydra:member'];
            })
        },
    }
}
