
import fileDownload from "../../mixins/file-download";

export default {
    name: "DownloadScheduleModal",
    mixins: [fileDownload],
    props: {
        eventId: {
            type: Number,
            required: false,
            default: null,
        },
        query: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            format: "docx",
            includeBriefingBook: -1,
            showBlockedTimes: false,
            presentationSchedule: {
                includePresentations: false,
                includeLogos: false,
                format: "docx"
            },
            scheduleCollapseShown: false,
            presentationCollapseShown: false,
            summaryCollapseShown: false,
        }
    },
    methods: {
        downloadSchedule() {
            const query = {
                ...this.query,
                options: {
                    format: this.format,
                    includeBriefingBook: this.includeBriefingBook,
                    showBlockedTimes: this.showBlockedTimes
                },
            };

            this.saveQueryAndExport(query, `/api/event_meeting_sheets/generate/${this.eventId}`).then(() => {
                this.$emit("download-triggered");
            });
        },
        downloadPanelPresentationSchedule() {
            const query = {
                ...this.query,
                options: {
                    includePresentations: this.presentationSchedule.includePresentations,
                    includeLogos: this.presentationSchedule.includeLogos,
                    format: this.presentationSchedule.format
                },
            };

            this.saveQueryAndExport(query, `/api/event_meeting_sheets/generate_presentation_panel/${this.eventId}`).then(() => {
                this.$emit("download-triggered");
            });
        },
        downloadInteractionSummaries() {
            this.saveQueryAndExport(this.query, `/api/event_meeting_sheets/generate_interaction_summaries/${this.eventId}`).then(() => {
                this.$emit("download-triggered");
            });
        }
    }
}
