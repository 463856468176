
import LoadingSpinner from "../loading-spinner";
import iriPreparation from "../../mixins/iri-preparation";
import ParticipantsByCompany from "./participants-by-company";

export default {
    name: "BulkInteraction",
    components: {LoadingSpinner, ParticipantsByCompany},
    mixins: [iriPreparation],
    props: {
        interaction: {
            type: Object,
            required: true,
        },
        radiosDisabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            interactionWorkingCopy: {},
            visibilityOptions: [
                {value: false, label: 'all'},
                {value: true, label: 'me'}
            ],
            relevanceOptions: [
                {value: 'relevant', label: 'Relevant'},
                {value: 'unclear', label: 'Unclear'},
                {value: 'irrelevant', label: 'Irrelevant'},
            ],
            listen: true,
            saving: false,
            autoSaveInterval: 1000 * 15,
            autoSaveTimeout: null,
            saved: false,
        }
    },
    computed: {
        selectedRelevance: {
            get() {
                switch (this.interactionWorkingCopy.isRelevant) {
                    case true:
                        return 'relevant';
                    case false:
                        return 'irrelevant';
                    default:
                        return 'unclear';
                }
            },
            set(newValue) {
                switch (newValue) {
                    case 'relevant':
                        this.$set(this.interactionWorkingCopy, "isRelevant", true);
                        break;
                    case 'irrelevant':
                        this.$set(this.interactionWorkingCopy, "isRelevant", false);
                        break;
                    default:
                        this.$set(this.interactionWorkingCopy, "isRelevant", null);
                }
            }
        }
    },
    watch: {
        interaction: {
            handler(val) {
                this.listen = false;
                const interactionToWorkWith = structuredClone(val);
                interactionToWorkWith.followUpDate = interactionToWorkWith.followUpDateFormatted;
                this.interactionWorkingCopy = interactionToWorkWith;
                this.$nextTick(() => {
                    this.listen = true;
                })
            },
            immediate: true
        },
        selectedRelevance: {
            handler() {
                if (this.listen) {
                    this.saveDelayed();
                }
            },
        },
        "interactionWorkingCopy.private": {
            handler() {
                if (this.listen) {
                    this.saveDelayed();
                }
            },
        },
        "interactionWorkingCopy.summary": {
            handler() {
                clearTimeout(this.autoSaveTimeout);
                if (this.listen && this.$refs.summaryTextarea) {
                    this.autoSaveTimeout = setTimeout(() => {
                        this.saveDelayed()
                    }, this.autoSaveInterval)
                }
            },

        },
        "interactionWorkingCopy.followUpItem": {
            handler() {
                clearTimeout(this.autoSaveTimeout);
                if (this.listen && this.$refs.followUpItemTextarea) {
                    this.autoSaveTimeout = setTimeout(() => {
                        this.saveDelayed()
                    }, this.autoSaveInterval)
                }
            },
        },
        "interactionWorkingCopy.followUpDate": {
            handler(val) {
                if (val === "") {
                    this.interactionWorkingCopy.followUpDate = null;
                    this.interactionWorkingCopy.followUpDateFormatted = null;
                }
                if (this.listen) {
                    this.saveDelayed();
                }
            },
        },
    },
    mounted() {
    },
    methods: {
        saveDelayed() {
            clearTimeout(this.autoSaveTimeout);
            this.autoSaveTimeout = setTimeout(this.saveInteraction, 150);
        },
        saveInteraction() {
            clearTimeout(this.autoSaveTimeout);

            this.saving = true;

            let interactionToProcess = structuredClone(this.interactionWorkingCopy);

            interactionToProcess = this.prepareIri(interactionToProcess);
            interactionToProcess.interactionParticipants = this.prepareIri(interactionToProcess.interactionParticipants);
            interactionToProcess.interactionHasCompanies = this.prepareIri(interactionToProcess.interactionHasCompanies);

            delete interactionToProcess.companies;

            this.$axios.patch(`/api/interactions/${this.interaction.id}/summarize`, interactionToProcess, {
                headers: {
                    'Content-Type': 'application/merge-patch+json'
                }
            }).then(() => {
                const toastId = this.generateUUID();
                this.addToast({
                    type: "success",
                    title: "Interaction saved",
                    message: "Changes are saved successfully",
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })

                this.saved = true
            }).finally(() => {
                this.saving = false;
            });
        }
    }
}
