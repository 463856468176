
export default {
    name: "FileIcon",
    props: {
        size: {
            type: String,
            default: ""
        },
        filename: {
            type: String,
            required: true
        }
    },
    computed: {
        icon() {
            const filetype = this.filename.split('.').pop().toLowerCase();
            switch (filetype) {
                case "pdf":
                    return ["far", "file-pdf"];
                case "jpg":
                case "jpeg":
                case "png":
                case "svg":
                    return ["far", "file-image"];
                case "zip":
                    return ["far", "file-archive"];
                case "xls":
                case "xlsx":
                    return ["far", "file-excel"];
                case "doc":
                case "docx":
                    return ["far", "file-word"];
                case "mp4":
                case "mov":
                    return ["far", "file-video"];
                case "ppt":
                case "pptx":
                    return ["far", "file-powerpoint"];
                case "mp3":
                    return ["far", "file-audio"];
                default:
                    return ["far", "file"];
            }
        }
    }
}
