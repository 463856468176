
import GmapsAddress from "../../gmaps-address.vue";
import autolink from "../../../mixins/autolink";

export default {
    name: "InteractionLocationCell",
    components: {GmapsAddress},
    mixins: [autolink],
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
}
