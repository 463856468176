
export default {
    name: "SyncCopyModal",
    props: {
        interaction: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {},
    methods: {
        addInteractionCopyToOutlook() {
            this.isLoading = true;
            this.$axios.post(`/api/interactions/sync_copy_to_outlook`, {
                interactionIds: [this.interaction.id],
            }).then((response) => {
                let type = "success";
                let message = "A copy has been added to outlook."
                if (response.data.failed > 0) {
                    type = "danger";
                    message = "Interaction could not be added."
                }

                const toastId = this.generateUUID();
                this.addToast({
                    type,
                    title: "Interaction Synchronization",
                    message,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                })
            })
                .finally(() => {
                    this.isLoading = false;
                    this.$emit('sync-finished');
                });
        }
    }
}
