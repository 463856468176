

import humanFilesize from "../mixins/human-filesize";
import LoadingSpinner from "./loading-spinner";
import PCheckIcon from "./p-check-icon";
import UploadDropZone from "./upload-drop-zone";

export default {
    name: "FileUploadInput",
    components: {
        UploadDropZone,
        LoadingSpinner,
        PCheckIcon,
    },
    mixins: [humanFilesize],
    props: {
        propertyName: {
            type: String,
            required: true,
        },
        displayName: {
            type: String,
            required: false,
            default: null,
        },
        type: {
            type: String,
            required: true,
        },
        value: {
            type: [Object],
            required: false,
            default: undefined,
        },
        currentFilename: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            removeFile: false,
            photoLoaded: false,
            originalFile: null,
            originalFileUrl: null,
            originalFilesEntry: null,
            highlightDropArea: false,
        };
    },
    computed: {
        acceptedFiles() {
            if (this.type === 'image') {
                return 'image/*';
            } else if (this.type === 'document') {
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            } else if(this.type === 'pdf') {
                return 'application/pdf';
            } else {
                return '*/*';
            }
        },
        file: {
            get() {
                return this.value[this.propertyName];
            },
            set(newValue) {
                this.$set(this.value, this.propertyName, newValue);
            },
        },
        fileUrl: {
            get() {
                return this.value[this.propertyName + 'Url'];
            },
            set(newValue) {
                this.$set(this.value, this.propertyName + 'Url', newValue);
            },
        },
        filesEntry: {
            get() {
                return this.value.files[this.propertyName + 'File'];
            },
            set(newValue) {
                if (this.value.hasOwnProperty('files')) {
                    this.$set(this.value.files, this.propertyName + 'File', newValue);
                }
            },
        },
        newFileSize() {
            if (this.file && this.file.size) {
                return this.file.size;
            }
            return 0;
        },
        newFileSizeHuman() {
            return this.getHumanFilesize(this.newFileSize);
        },
        label() {
            return (this.displayName || this.labelFromPropertyName);
        },
        labelFromPropertyName() {
            return this.ucfirst(this.propertyName.replace(/[A-Z]/, ' $1'));
        },
        previewPhotoUrl() {
            if (this.type !== 'image') {
                return null;
            }

            return this.fileUrl;
        },
        container() {
            return this.value;
        },
    },
    watch: {
        removeFile(val) {
            if (val) {
                this.file = null;
                this.fileUrl = null;
                this.filesEntry = null;
                this.photoLoaded = false;
                this.$emit("input", this.value);
            } else {
                this.resetFile();
            }
        },
    },
    mounted() {
        if (this.value.files === undefined) {
            this.$set(this.value, 'files', {});
        }
        this.originalFile = this.file;
        this.originalFileUrl = this.fileUrl;
        this.originalFilesEntry = this.filesEntry;
    },
    methods: {
        dragleave(event) {
            this.highlightDropArea = false;
        },
        dragover(event) {
            event.preventDefault();
            this.highlightDropArea = true;
        },
        drop(event) {
            event.preventDefault();
            this.highlightDropArea = false;
            event.dataTransfer.files.forEach((file) => {
                this.setFileData(file);
            })
        },
        fileSelectHandler(fileList) {
            if (fileList.length === 0) {
                return;
            }
            this.setFileData(fileList[0]);
        },
        setFileData(file) {
            this.file = file;
            this.fileUrl = URL.createObjectURL(file);
            this.photoLoaded = false;
            const reader = new FileReader();
            reader.readAsDataURL(this.file)
            reader.onload = () => {
                this.filesEntry = {
                    extension: this.file.name.split('.').pop(),
                    data: reader.result,
                };
                this.$emit("input", this.value);
            };
        },
        photoLoadHandler(event) {
            this.photoLoaded = true;
        },
        resetFile() {
            this.file = this.originalFile;
            this.fileUrl = this.originalFileUrl;
            this.filesEntry = this.originalFilesEntry;
            this.photoLoaded = false;
            this.$emit("input", this.value);
        },
        ucfirst(string) {
            return (string[0].toUpperCase() + string.slice(1));
        },
    }
}
