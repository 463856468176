
export default {
    name: "InteractionFollowUpCell",
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
}
