
export default {
    name: "ConflictsWarning",
    props: {
        conflicts: {
            type: Array,
            default: () => []
        }
    }
}
