
import {mapActions} from "vuex";

export default {
    name: "InteractionSubjectCell",
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
        }
    },
    watch: {
        cell() {
            console.log(this.cell);
        }
    },
    methods: {
        ...mapActions({
            setPreventErrorHandling: "app/setPreventErrorHandling",
            setCurrentError: 'app/setCurrentError',
        }),
        updateInteraction() {
            this.isLoading = true;
            // Sending a GET request for this specific interaction will make sure it is up to date
            this.setPreventErrorHandling(true);
            this.$axios.get(`/api/interactions/${this.cell.id}`)
                .then(() => {
                    this.$emit('update');
                })
                .catch((err) => {
                    if (err.response && err.response.status && err.response.status === 404) {
                        // Interaction does not exist anymore -> reload table
                        this.$emit('update');
                    } else {
                        this.setCurrentError(err);
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                    this.setPreventErrorHandling(false);
                })
        }
    }
}
