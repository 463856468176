
import EditingHistory from "./editing-history";

export default {
    name: "HistoryModal",
    components: {
        EditingHistory
    },
    props: {
        entityId: {
            type: Number,
            required: true,
        },
        historyType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showHistory: false,
        }
    },
    computed: {
        ajaxUrl() {
            return `/api/history/${this.historyType}/${this.entityId}`;
        },
        title() {
            return (this.ucfirst(this.historyType.replaceAll(/([A-Z])/g, ' $1')) + ' History');
        },
    },
    mounted() {
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
            this.showHistory = true;
        });

        this.$refs.modal.addEventListener('hide.bs.modal', () => {
            this.showHistory = false;
        });
    },
    methods: {
        ucfirst(string) {
            return (string[0].toUpperCase() + string.slice(1));
        },
    },
}
