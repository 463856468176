

export default {
    name: "InfoPopover",
    props: {
        inline: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String,
            default: "right"
        },
        title: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            uuid: this.generateUUID(),
            timer: 1,
            popover: null,
        }
    },
    mounted() {
        const content = this.$refs.content;
        this.popover = new this.$bootstrap.Popover(this.$refs.popover, {
            toggle: "popover",
            container: "body",
            html: true,
            trigger: "focus",
            placement: this.position,
            title: this.title,
            content,
        });
    }
}
