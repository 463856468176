
import VueReadMoreSmooth from "vue-read-more-smooth";

export default {
    name: "QuickAddParticipants",
    components: {VueReadMoreSmooth},
    props: {
        interactionId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            selectedCompany: null,
            selectedContact: null,
            loading: false,
            isAnySelectOpen: false,
        }
    },
    watch: {
        selectedCompany: {
            handler() {
                this.addCompanyToInteraction();
            },
            deep: true,
        },
        selectedContact: {
            handler() {
                this.addContactToInteraction();
            },
            deep: true,
        }
    },
    methods: {
        addCompanyToInteraction() {
            if (this.selectedCompany) {
                this.loading = true;
                this.$axios.post(`/api/interactions/${this.interactionId}/add_company`, this.selectedCompany)
                    .then((response) => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Company added",
                            message: response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                        this.$emit("update");
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.selectedCompany = null;
                        this.loading = false;
                    });
            }
        },
        addContactToInteraction() {
            if (this.selectedContact) {
                this.loading = true;
                this.$axios.post(`/api/interactions/${this.interactionId}/add_contact`, this.selectedContact)
                    .then((response) => {
                        const toastId = this.generateUUID();
                        this.addToast({
                            type: "success",
                            title: "Contact added",
                            message: response.data.message,
                            id: toastId,
                        });
                        this.$nextTick(() => {
                            this.toggleToast(toastId);
                        });
                        this.$emit("update");
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally(() => {
                        this.selectedContact = null;
                        this.loading = false;
                    });
            }
        },
        onSelectOpen() {
            this.isAnySelectOpen = true;
        },
        onSelectClose() {
            this.isAnySelectOpen = false;
        }
    }
}
