
import SaveButton from "../save-button.vue";
import iriPreparation from "../../mixins/iri-preparation";

export default {
    name: "ManageEditorsModal",
    components: {SaveButton},
    mixins: [iriPreparation],
    props: {
        targetingGroup: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            editorsWorkingCopy: {},
        }
    },
    methods: {
        resetWorkingCopy() {
            this.editorsWorkingCopy = structuredClone(this.targetingGroup.editorUsers);
        },
        submit() {
            this.loading = true;
            this.$axios.patch(`/api/targeting_groups/${this.targetingGroup.id}`, {
                editorUsers: this.prepareIri(this.editorsWorkingCopy)
            }, {
                headers: {'Content-Type': 'application/merge-patch+json'}
            }).then(r => {
                this.$emit('hide-modal');
                this.$emit('targeting-group-updated')
            }).finally(() => {
                this.loading = false;
            })
        },
    },
}
