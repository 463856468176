
export default {
    name: "InfoText",
    props:{
        text:{
            type:String,
            default:null
        },
        inline:{
            type:Boolean,
            default: false
        }
    }
}
