
import ParticipantsByCompany from "../participants-by-company.vue";
import QuickAddParticipants from "../quick-add-participant.vue";

export default {
    name: "InteractionParticipantsCell",
    components: {QuickAddParticipants, ParticipantsByCompany},
    props: {
        cell: {
            type: Object,
            required: true,
        },
        allowEdit: {
            type: Boolean,
            default: true,
        },
    },
}
