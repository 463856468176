
import VueReadMoreSmooth from "vue-read-more-smooth";

export default {
    name: "ParticipantsByCompany",
    components: {VueReadMoreSmooth},
    props: {
        participants: {
            type: Array,
            required: true,
        },
        companies: {
            type: Array,
            default: () => [],
        },
        target: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            uuid: this.generateUUID()
        }
    },
    computed: {
        participantCompaniesWithoutIdOne() {
            const companies = this.participantCompanies.filter(company => company.id !== 1);
            return companies;
        },
        hcwCompany() {
            return this.participantCompanies.find(company => company.id === 1) || null;
        },
        participantCompanies() {
            const companies = {};
            for (let i = 0; i < this.participants.length; i++) {
                const company = this.participants[i].contact.company;
                if (company && !companies.hasOwnProperty(company.id)) {
                    companies[company.id] = company;
                }
            }
            return Object.values(companies);
        },
        participantsByCompany() {
            const participantsByCompany = {};
            for (let i = 0; i < this.participants.length; i++) {
                const company = this.participants[i].contact.company;
                if (company) {
                    if (participantsByCompany.hasOwnProperty(company.id)) {
                        participantsByCompany[company.id].push(this.participants[i]);
                    } else {
                        participantsByCompany[company.id] = [this.participants[i]];
                    }
                }
            }
            return participantsByCompany;
        },
        participantsWithoutCompany() {
            const participantsWithoutCompany = [];
            for (let i = 0; i < this.participants.length; i++) {
                if (!this.participants[i].contact.company) {
                    participantsWithoutCompany.push(this.participants[i]);
                }
            }
            return participantsWithoutCompany;
        },
        companiesWithoutParticipants() {
            const companies = [];
            for (let i = 0; i < this.companies.length; i++) {
                if (!this.participantsByCompany.hasOwnProperty(this.companies[i].id)) {
                    companies.push(this.companies[i]);
                }
            }
            return companies;
        }
    },
}
