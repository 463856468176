
export default {
    name: "InteractionEventCell",
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
}
