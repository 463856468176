
export default {
    name: "InteractionIndicatorsCell",
    props: {
        cell: {
            type: Object,
            required: true,
        },
        allowEdit: {
            type: Boolean,
            default: true,
        },
        addNoteLoading: {
            type: Number,
            default: null
        },
        editLoading: {
            type: Number,
            default: null
        },
    },
    methods: {
        addNoteToInteraction(interactionId) {
            this.$emit("add-note-to-interaction", interactionId);
        },
        editInteraction(id) {
            this.$emit("edit-interaction", id);
        },
    },
}
